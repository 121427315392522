<template>
    <div class="functions">
        <v-toolbar class="custom-toolbar mb-4" flat dense>
            <v-btn icon>
                <v-icon size="40" @click="goBack">
                    mdi-arrow-left-bold-circle
                </v-icon>
            </v-btn>
            <v-toolbar-title class="custom-toolbar-title">
                {{ 'Function Plans' }}
            </v-toolbar-title>
            <img :src="logo" class="toolbar-icon" />
        </v-toolbar>

        <v-container>
            <!-- Function Plans Section -->
            <div class="function-plans">
                <v-row class="align-center function-plans-row">
                    <v-col cols="12" md="6" class="text-left function-plans-text">
                        <h3>Choose the perfect plan for your business needs</h3>
                    </v-col>
                    <v-col cols="12" md="6" class="text-md-right text-left">
                        <span class="pricing-info">Spreads cost, easy to budget</span>
                        <v-btn class="ml-4 monthly-method-btn" variant="tonal">Monthly Method</v-btn>
                    </v-col>
                </v-row>
            </div>

            <!-- Pricing Options Section -->
            <div class="plans-container">
                <div class="pricing-table-wrapper">
                    <table class="pricing-table">
                        <thead>
                            <tr class="header-background">
                                <th>All Functions</th>
                                <th class="plan-header">
                                    Starter
                                    <span class="month-text">/ Month</span>
                                    <div>
                                        <a href="https://pinestack.io/en/contact/" target="_blank"
                                            rel="noopener noreferrer">
                                            <v-btn class="buy-now-btn" variant="outlined" style="color: #5DA6F3;">
                                                Contact Us
                                            </v-btn>
                                        </a>
                                    </div>
                                </th>
                                <th class="plan-header popular">
                                    Business
                                    <span class="month-text">/ Month</span>
                                    <div>
                                        <a href="https://pinestack.io/en/contact/" target="_blank"
                                            rel="noopener noreferrer">
                                            <v-btn class="buy-now-btn"
                                                style="background-color: #5DA6F3; color: white; font-weight: bold;"
                                                variant="tonal">
                                                Contact Us
                                            </v-btn>
                                        </a>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="feature in features" :key="feature.name">
                                <td class="feature-name">{{ feature.name }}</td>
                                <td>
                                    <v-icon v-if="feature.starter === true" class="check-icon">mdi-check-circle</v-icon>
                                    <span v-else-if="feature.starter === 'Limited'" class="limited">
                                        <v-icon class="check-icon">mdi-check-circle</v-icon> (Limited)
                                    </span>
                                    <span v-else class="dash">-</span>
                                </td>
                                <td>
                                    <v-icon v-if="feature.business" class="check-icon">mdi-check-circle</v-icon>
                                    <span v-else class="dash">-</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { getToolbarLogoImage } from "@/assets/Branding/branding";

export default defineComponent({
    name: "Functions",
    setup() {
        const logo = ref("");
        const router = useRouter();

        const features = ref([
            { name: "Progressive Web App (PWA)", starter: true, business: true },
            { name: "User Authentication", starter: true, business: true },
            { name: "Announcements & Communication Tools", starter: true, business: true },
            { name: "Basic Booking System", starter: true, business: true },
            { name: "FAQs Support", starter: true, business: true },
            { name: "Analytics Overview", starter: "Limited", business: true },
            { name: "User Tools: Feedback, Profile Updates, Incident Reporting", starter: true, business: true },
            { name: "Multilingual App Settings with Privacy Policy", starter: true, business: true },
            { name: "Admin Tools: Manage Feedbacks & Bookings", starter: true, business: true },
            { name: "Native Mobile Apps (Android & iOS)", starter: false, business: true },
            { name: "AI-Driven Motivation Center", starter: false, business: true },
            { name: "Seamless Outlook Integration", starter: false, business: true },
            { name: "Advanced Workspace Management Tools", starter: false, business: true },
            { name: "AI-Powered Chat Support", starter: false, business: true },
            { name: "Detailed Business Analytics", starter: false, business: true },
            { name: "Enhanced User Tools: Vouchers, Intelligent Chatbot", starter: false, business: true },
            { name: "Admin Tools: User & Incident Management", starter: false, business: true },
        ]);

        onMounted(async () => {
            logo.value = await getToolbarLogoImage();
        });

        const goBack = () => {
            router.go(-1);
        };

        return {
            logo,
            features,
            goBack,
        };
    },
});
</script>

<style scoped>
/* Toolbar Styling */
.custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

.custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333;
    margin-left: 10px;
}

.toolbar-icon {
    height: 30px;
}

.functions {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    padding: 1rem;
}

/* Function Plans Section */
.function-plans {
    margin-bottom: 1rem;
    padding-left: 1rem;
}

.function-plans h3 {
    font-size: 1.4rem;
    font-weight: bold;
    color: #5e5e5e;
}

.function-plans .pricing-info {
    font-weight: bolder;
}

.monthly-method-btn {
    margin-top: 0.5rem;
    background-color: #5DA6F3;
    color: white;
}

/* Pricing Table */
.pricing-table-wrapper {
    display: flex;
    justify-content: center;
}

.pricing-table {
    width: 95%;
    max-width: 1400px;
    border-collapse: collapse;
    background: white;
    border-radius: 8px;
    overflow: hidden;
}

.header-background {
    background-color: #D0DBED;
    font-weight: bold;
    padding: 12px;
}

.pricing-table th,
.pricing-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #d1d5db;
}

.plan-header {
    font-weight: bold;
    text-align: center;
    position: relative;
    padding-bottom: 20px;
}

.month-text {
    color: #777;
    font-size: 0.8rem;
    font-weight: bold;
}

.buy-now-btn {
    margin-top: 5px;
    font-size: 0.8rem;
}

.feature-name {
    color: #5e5e5e;
}

.limited {
    color: #5e5e5e;
}

.check-icon {
    color: #11B57E;
}

.dash {
    display: inline-block;
    width: 20px;
    text-align: center;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .function-plans {
        text-align: center;
    }

    .function-plans h2 {
        font-size: 1.3rem;
        font-weight: bold;
    }

    .pricing-table {
        width: 100%;
        font-size: 0.8rem;
    }

    .buy-now-btn {
        font-size: 0.6rem;
    }
}
</style>



<!--
Functions Component

This component provides users with a user interface to view and select different function plans for their business needs. The main features of this component include a toolbar, a logo, and a section displaying various plans with their respective features.

Toolbar
The toolbar at the top of the component includes a back button represented by an icon. When users click this icon, they are navigated back to the previous page. The toolbar also displays the title "Functions" and a logo image. The logo is dynamically loaded when the component is mounted.

Plans Section
Below the toolbar, users can see the "Functions Options" section, which includes a header with a title and a description. This section helps users understand that they need to choose a plan that best fits their business requirements.

Plan Cards
The plans are displayed as cards in a responsive layout. Each card represents a different plan and includes the plan name and a list of features. The features are displayed with a checkmark icon to indicate that they are included in the plan. The "Business" plan is highlighted with a "Most Popular" badge to attract users' attention.

Visual Feedback
The component provides visual feedback through the use of icons, badges, and styling. For example, the "Most Popular" badge is styled to stand out, and the checkmark icons next to the features provide a clear indication of what is included in each plan. The toolbar and plan cards have a modern design with shadows and rounded corners to enhance the user experience.

Dynamic Behavior
The logo image is dynamically loaded when the component is mounted using the onMounted lifecycle hook. The back button uses the Vue Router's go method to navigate back to the previous page.

Overall, this component offers a clean and intuitive interface for users to explore and select different function plans, making it easy for them to understand the features and benefits of each plan.
-->
